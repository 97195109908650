<template>
  <div class="card">
    <div>
      <div class="card__header">
        <figure class="card__header-logo">
          <image-loader :id="purchase.merchantLogo" max-width="100%" />
        </figure>
        <div class="is-flex is-flex-direction-column ml-2">
          <span class="has-text-weight-medium is-size-5">
            {{ purchase.merchantFullName }}
          </span>
          <span class="has-text-grey">
            {{ purchase.merchantWebsite }}
          </span>
        </div>
        <purchase-status :status="purchase.status" class="ml-2" />
      </div>

      <div class="card__content">
        <div class="card__content-group">
          <div>
            <p class="is-uppercase mb-1 has-text-grey-icon is-size-14">
              {{ $t("af:purchase.history.card.label.down_payment") }}
            </p>
            <span class="has-text-weight-medium">{{
              `${monify(purchase.downPayment)} ${$t(
                "af:purchase.details.label.down_payment_unit"
              )}`
            }}</span>
          </div>
          <div class="card__content-separator" />
          <div>
            <p class="is-uppercase mb-1 has-text-grey-icon is-size-14">
              {{ $t("af:purchase.history.card.label.financed_part") }}
            </p>
            <span class="has-text-weight-medium">{{
              `${monify(purchase.loanAmount)} ${$t(
                "af:purchase.details.label.loan_amount_unit"
              )}`
            }}</span>
          </div>
          <div class="card__content-separator" />
        </div>
        <div class="card__content-group">
          <div>
            <p class="is-uppercase mb-1 has-text-grey-icon is-size-14">
              {{ $t("af:purchase.history.card.label.financing_mode") }}
            </p>
            <span class="has-text-weight-medium">{{ purchase.offerName }}</span>
          </div>
          <div class="card__content-separator" />
          <div>
            <p class="is-uppercase mb-1 has-text-grey-icon is-size-14">
              {{ $t("af:purchase.history.card.label.thm") }}
            </p>
            <span class="has-text-weight-bold">{{
              `${purchase.thm.toFixed(2)}&nbsp;${$t(
                "af:purchase.details.label.thm_unit"
              )}`
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card__side-content">
      <span class="has-text-weight-medium">{{
        `${monify(purchase.totalAmount)} ${$t(
          "af:purchase.details.label.total_cost_unit"
        )}`
      }}</span>
      <p class="has-text-grey-icon">
        {{ $t("af:purchase.history.card.label.basket_cost") }}
      </p>
      <b-button type="is-info" @click="triggerDetailEmit">
        {{ $t("af:purchase.history.card.button.details") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { formatSeconds, monify } from "@/utils/util";

export default {
  name: "PurchaseCard",
  components: {
    PurchaseStatus: () => import("@/components/offers/PurchaseStatus.vue"),
    ImageLoader: () => import("@/components/document/ImageLoader.vue"),
  },
  props: {
    purchase: {
      type: Object,
      required: true,
    },
  },
  methods: {
    triggerDetailEmit() {
      this.$emit("viewDetails");
    },
  },
  data() {
    return {
      formatSeconds,
      monify,
    };
  },
};
</script>

<style scoped lang="scss">
.card {
  flex-direction: column;
  padding: 16px;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;

    &-logo {
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
      margin: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    &-group {
      display: flex;
      margin-top: 1rem;
      justify-content: space-between;
      min-width: 100%;
    }

    &-separator {
      width: 1px;
      height: 2rem;
      background-color: lightgray;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  &__side-content {
    margin-top: 1rem;
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) {
  .card {
    flex-direction: row;

    &__side-content {
      margin-top: 0;
      text-align: right;
    }
  }
}
@media only screen and (min-width: 1000px) {
  .card {
    padding: 32px 36px;

    &__header {
      flex-direction: row;
      gap: 0;
      text-align: left;
    }

    &__content {
      flex-wrap: nowrap;
    }
  }
}
@media only screen and (min-width: 600px) {
  .card__content-group {
    min-width: auto;
    justify-content: center;
  }
}
</style>
